loadjs([
  '/assets/scripts/array.from.js',
  '/assets/scripts/functions.js',
  '/assets/scripts/app.js',
],
{
  async: false,
  success: () => {
    ready( () => {
      document.documentElement.className += ' ' + navigator.sayswho.toLowerCase().replace(' ', '-')
      // Document Ready
      initCookieConsent()
      initializeMobileNav()
      deactivateLinks()
      initFloatingLabels()
      initRequiredInputs()
      initFileUpload()

      masonry('.nav__wrapper > div > ul', 'li')

      // Fix CSRF inputs
      if (query('[name="' + csrfTokenName + '"]').length > 0) {
        let csrfInputs = query('[name="' + csrfTokenName + '"]')
        csrfInputs.forEach(csrfInput => {
          csrfInput.value = csrfTokenValue
        })
      }

      if (query('.locations__toggle').length > 0) {
        initLocationsToggle()
      }

      if (query('.cards-filter__tabs').length > 0) {
        initCardsFilterTabs()
      }

      if (document.getElementsByTagName('select').length > 0) {
        loadjs([
          '/assets/vendor/dropkick/dropkick.css',
          '/assets/vendor/dropkick/dropkick.js',
          '/assets/styles/dropkick.css'
        ],
        {
          success: () => {
            let selects = [...document.querySelectorAll('select:not(.sas):not([data-dkcacheid])')]
            selects.forEach(select => {
              let dkSelect = new Dropkick(select)
            })

            if (query('#jump-nav').length > 0) {
              initJumpNav()
            }
          }
        })
      }

      if (query('.featured-cards--slider').length > 0) {
        initFeaturedCardsSlider()
      }

      if (query('.banner--image').length > 0) {
        initBannerSlider()
      }

      if (query('.profile-cards__nav').length > 0) {
        initStickyAlphabet()
      }

      if (query('.link-list').length > 0) {
        initStickyLocations()
      }

      if (query('.side-list').length > 0) {
        initSideList()
      }

      if (query('.show-more').length > 0) {
        showMoreInit()
      }

      if (query('#filter-news').length > 0) {
        filterNews()
      }

      if (query('.sidebar-contact').length > 0) {
        initSidebarForms()
      }

      if (query('form[ajax]').length > 0) {
        initAjaxForms()
      }

      if (query('#submit-an-assignment').length > 0) {
        stickyProgress()
        loadjs([
          'https://www.google.com/recaptcha/api.js?render=explicit',
          '/assets/scripts/vue.min.js',
          '/assets/scripts/vue-resource.min.js',
          '/assets/scripts/vue-the-mask.js',
          '/assets/scripts/js.cookie.js',
          '/assets/vendor/dropkick/dropkick.css',
          '/assets/vendor/dropkick/dropkick.js',
          '/assets/styles/dropkick.css',
          '/assets/scripts/submit-an-assignment.js'
        ], { async: false })
      }

      if (query('#liberty-mutual').length > 0) {
        stickyProgress()
        loadjs([
          'https://www.google.com/recaptcha/api.js?render=explicit',
          '/assets/scripts/vue.min.js',
          '/assets/scripts/vue-resource.min.js',
          '/assets/scripts/vue-the-mask.js',
          '/assets/scripts/js.cookie.js',
          '/assets/vendor/dropkick/dropkick.css',
          '/assets/vendor/dropkick/dropkick.js',
          '/assets/styles/dropkick.css',
          '/assets/scripts/liberty-mutual.js'
        ], { async: false })
      }

      if (query('.aside--sticky').length > 0) {
        loadjs.ready('stickybits',
        {
          async: false,
          success: () => {
            stickybits('.aside--sticky > div', { stickyBitStickyOffset: 32 })
            let stickyDivs = query('.aside--sticky > div')
            let observer = new MutationObserver(mutations => {
              mutations.forEach(mutation => {
                if (mutation.attributeName == 'class') {
                  if (hasClass('js-is-sticky', mutation.target)) {
                    mutation.target.style.width = mutation.target.parentNode.offsetWidth + 'px'
                  } else {
                    mutation.target.style.width = ''
                  }
                }
              })
            })
            stickyDivs.forEach(stickyDiv => {
              observer.observe(stickyDiv, { attributes: true })
            })
          }
        })
      }

      if (document.querySelectorAll('[href="#search"]').length > 0) {
        let searchLinks = query('[href="#search"]')
        searchLinks.forEach(searchLink => {
          searchLink.addEventListener('click', e => {
            e.preventDefault()
            toggleClass('show', document.getElementById('search-tray'))
            setTimeout(() => {
              document.querySelector('#search-tray [name="query"]').focus()
            }, 125)
          })
        })
        let searchCloseLinks = query('#search-tray [href="#close"]')
        searchCloseLinks.forEach(searchCloseLink => {
          searchCloseLink.addEventListener('click', e => {
            e.preventDefault()
            removeClass('show', closest('#search-tray', searchCloseLink))
          })
        })
      }

      if (document.querySelectorAll('.search-query a').length > 0) {
        document.querySelector('.search-query a').addEventListener('click', e => {
          let searchQuery = closest('.search-query', e.target)
          let searchInput = searchQuery.nextSibling.querySelector('input[name="query"]')
          addClass('hide', searchQuery)
          searchInput.focus()
          searchInput.selectionStart = searchInput.selectionEnd = searchInput.value.length
        })
      }

      if (document.querySelectorAll('[href="#submit"]').length > 0) {
        let searchLinks = query('[href="#submit"]')
        searchLinks.forEach(searchLink => {
          searchLink.addEventListener('click', e => {
            e.preventDefault()
            let form = closest('form', searchLink)
            form.submit()
          })
        })
      }

      matchHeight('.listing--wide .listing__item > *')
      matchHeight('.feed-list.feed-list--2-up .feed-list__wrapper ul.feed-list__listing li a.external span')

      // Handle Location Markers Tooltip
      if (document.querySelectorAll('.figure__dot').length > 0) {
        let figureDots = query('.figure__dot')
        figureDots.forEach(function (figureDot) {
          figureDot.onmouseover = function(event) {
            if (this !== event.currentTarget) { return false }
            // mouse enter
            figureDot.previousSibling.style.opacity = 1

          }
          figureDot.onmouseout = function(event) {
            if (this !== event.currentTarget) { return false }
            // mouse leave
            figureDot.previousSibling.style.opacity = 0
          }
        })
      }
    })

    // masonry('.profile-cards__cards', '.profile-cards__card')

    // if (query('.profile-cards__card').length > 0) {
    //   loadjs([
    //     '/assets/scripts/geolib.min.js',
    //   ],
    //   {
    //     async: false,
    //     success: () => {
    //       calculateDistance()
    //     }
    //   })
    // }

    loadjs([
      '/assets/scripts/lazysizes-plugins/ls.bgset.js',
      '/assets/scripts/lazysizes-plugins/ls.respimg.min.js',
      '/assets/scripts/lazysizes-plugins/ls.attrchange.min.js',
      '/assets/scripts/lazysizes.js',
    ],
    {
      async: false,
      success: () => {
        document.addEventListener('lazybeforeunveil', e => {
          let bg = e.target.getAttribute('data-bg')
          if (bg) {
            e.target.style.backgroundImage = 'url(' + bg + ')'
          }
          if (e.target.getAttribute('map-api-key')) {
            loadjs.ready('googlemaps',
            {
              success: () => {
                initMaps()
              }
            })
          }
        })
      }
    })
  }
})
